import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/mdx-layout.tsx";
import { PopularStories } from "components/organisms/popular-stories";
import PlayDatingVideo from "src/videos/PlayDating.mp4";
import PlayLocationChangesVideo from "src/videos/PlayLocationChanges.mp4";
import PlayCharacterFieldsVideo from "src/videos/PlayCharacterFields.mp4";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const VideoLoop = makeShortcode("VideoLoop");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`How do you play a story?`}</h1>
    <p>{`Wrdie is a platform for interactive stories. `}</p>
    <p>{`Interactive stories present the reader with decisions. These decisions change the story and can change its outcome.`}</p>
    <VideoLoop width="720px" source={PlayDatingVideo} mt="-1em" mb="14" mdxType="VideoLoop" />
    <p>{`Wrdie stories can make you the protagonist with text customized to your
name and pronouns.`}</p>
    <VideoLoop width="720px" source={PlayCharacterFieldsVideo} mt="-1em" mb="14" mdxType="VideoLoop" />
    <p>{`Wrdie stories may also be open ended. Rather than tell you where the
character goes and what they do -they put that power in your hands.
You chose where to go next. You make all of the moves.`}</p>
    <VideoLoop width="720px" source={PlayLocationChangesVideo} mt="-1em" mb="14" mdxType="VideoLoop" />
    <h2>{`What's next?`}</h2>
    <p>{`Check out the stories below and click the button to start playing.
You can also visit the `}<a parentName="p" {...{
        "href": "%22/discover%22"
      }}>{`Discover page`}</a>{` to see the complete list of published stories.`}</p>
    <h2>{`Here are some of our most popular stories`}</h2>
    <PopularStories mdxType="PopularStories" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      